/* eslint-disable react-hooks/exhaustive-deps */
import { RoleFunction } from '@eagle/common';
import { Account } from '@eagle/core-data-types';
import {
  App,
  AppBanner,
  AuthStatus,
  Avatar,
  DrawerContent,
  FeatureIcons,
  FILTERS_KEY,
  IfFlag,
  MenuFooter,
  MenuItem,
  menuItemStyle,
  MenuLink,
  MenuNavigation,
  MiddleSpinner,
  PageContent,
  PortalFeatureIcons,
  PortalHeader,
  PortalIcons,
  T_MANY,
  Undefinable,
  useAuth,
  useAuthenticated,
  useCustomRoutes,
  useDomainTheme,
  useDynamicModule,
  useFlags,
  useHasAuthorization,
  useIntercomGuide,
  usePromise,
  useSmallScreen
} from '@eagle/react-common';

import { Box, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import stc from 'string-to-color';
import { useRoutes } from './route';

const USER_ROLE = [RoleFunction.USER] as const;
const DATA_VIEWER_ROLE = [RoleFunction.DATA_VIEWER] as const;

const ADMIN_PORTAL_ROLES = [RoleFunction.ADMINISTRATOR] as const;
const ALERT_ROLES = [RoleFunction.ALERT_VIEWER] as const;
const MANAGEMENT_PORTAL_ROLES = [RoleFunction.INSTALLER, RoleFunction.SYSTEM_ADMINISTRATOR] as const;

const Body: FC = (): JSX.Element => {
  const { t } = useTranslation(['common', 'terms', 'track']);
  const { account, restClient, user, userInfo } = useAuthenticated();
  const { switchAccount, switchBackToOriginalAccount, state } = useAuth();
  const routes = useRoutes();
  const location = useLocation();
  const smallScreen = useSmallScreen();
  const theme = useTheme();
  const navigate = useNavigate();
  const { hasAuthorization } = useHasAuthorization(state);
  const flags = useFlags();
  const { setDomainTheme } = useDomainTheme();
  const { module, loaded: moduleLoaded } = useDynamicModule<FeatureIcons>('feature-icons', PortalFeatureIcons.Tracking);
  const {
    alert,
    alerts,
    analytics,
    dashboards,
    people,
    person,
    reports,
    thing,
    things,
  } = useCustomRoutes();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useIntercomGuide();

  const hasBasicAuthorization = hasAuthorization(USER_ROLE) && hasAuthorization(DATA_VIEWER_ROLE);
  const hasAdminPortalPermissions = hasAuthorization(ADMIN_PORTAL_ROLES);
  const hasAlertPermissions = hasAuthorization(ALERT_ROLES);
  const hasManagementPortalPermissions = hasAuthorization(MANAGEMENT_PORTAL_ROLES);

  const setActive = (path: string): string => { return location.pathname.indexOf(path) > -1 ? 'active' : ''; };
  const showAppBanner = userInfo.accountId !== userInfo.baseAccountId;

  const [accountsList] = usePromise<Undefinable<Account[]>>(
    async () => {
      const accounts = await restClient.my.accounts.getAll();
      if (accounts.length > 0) {
        return accounts.filter((res) => res._id !== account._id);
      }
    },
    [restClient]
  );

  useEffect(() => {
    setDomainTheme(account.homeDomain);
  }, [account.homeDomain]);

  useEffect(() => {
    if (state && state.status === AuthStatus.AUTHENTICATED) {
      setIsLoading(false);
    }
  }, [state]);

  const renderDrawerContent = (open: boolean): JSX.Element => (
    <DrawerContent
      profile={
        <Box sx={{ display: 'flex' }} data-testid="profile-section" id="profile">
          <Avatar
            background={theme.sidebar.avatar.background}
            iconColor={theme.sidebar.avatar.iconColor}
            handleNavigate={() => flags['portals-profile-feature'] ? navigate('/profile') : null}
            onSelected={async (value: string) => {
              await switchAccount(value);
              localStorage.setItem(FILTERS_KEY, JSON.stringify([]));
            }}
            options={accountsList}
            showIconOnly={!open}
            spacing={smallScreen ? 1 : 3}
            subtitle={account.display}
            title={user.display}
            tooltip={flags['portals-profile-feature'] ? t('common:page.profile.title') : ''}
          />
        </Box>
      }
      header={<PortalHeader.Generic collapsed={!open} />}
      navigation={
        <MenuNavigation
          hasPermissions={hasAdminPortalPermissions || hasManagementPortalPermissions}
          menuItems={[
            {
              active: true,
              'data-testid': 'portal-navigation-track',
              hasPermissions: true,
              icon: <PortalIcons.Tracking />,
              path: '/track/',
              sx: { whiteSpace: 'nowrap', ...menuItemStyle },
              text: t('common:common.labels.track'),
            },
            {
              'data-testid': 'portal-navigation-admin',
              hasPermissions: hasAdminPortalPermissions,
              icon: <PortalIcons.Administration />,
              path: '/admin/',
              sx: { mt: 1, whiteSpace: 'nowrap', ...menuItemStyle },
              text: t('common:common.labels.admin'),
            },
            {
              'data-testid': 'portal-navigation-manage',
              hasPermissions: hasManagementPortalPermissions,
              icon: <PortalIcons.Management />,
              path: '/manage/',
              sx: { my: 1, whiteSpace: 'nowrap', ...menuItemStyle },
              text: t('common:common.labels.manage'),
            },
          ]}
          open={open}
        />
      }
      footer={<MenuFooter open={open} showHelp />}
    >
      {hasBasicAuthorization
        && <>
          {account.config?.portals?.tracking?.dashboard?.sisenseId
            ? <IfFlag flag="track-overview-feature">
              <MenuLink to="/">
                <MenuItem
                  data-testid="overview-menu-item"
                  icon={module?.OverviewIcon && <module.OverviewIcon />}
                  open={open}
                  sx={{ ...menuItemStyle }}
                  text={t('track:page.overview.title')}
                />
              </MenuLink>
            </IfFlag>
            : undefined
          }
          <IfFlag flag="track-reports-feature">
            <MenuLink data-testid="reports-button" className={setActive(`/${reports}/`)} to={`/${reports}`}>
              <MenuItem
                data-testid="reports-menu-item"
                icon={module?.ReportsIcon && <module.ReportsIcon />}
                open={open}
                sx={{ ...menuItemStyle }}
                text={t('track:page.reports.title')}
              />
            </MenuLink>
          </IfFlag>
          <IfFlag flag="track-dashboards-feature">
            <MenuLink data-testid="dashboards-button" className={setActive(`/${dashboards}/`)} to={`/${dashboards}`}>
              <MenuItem
                data-testid="dashboards-menu-item"
                icon={module?.DashboardIcon && <module.DashboardIcon />}
                open={open}
                sx={{ ...menuItemStyle }}
                text={t('track:page.dashboards.title')}
              />
            </MenuLink>
          </IfFlag>
          <IfFlag flag="track-analytics-feature">
            <MenuLink data-testid="analytics-button" className={setActive(`/${analytics}/`)} to={`/${analytics}`}>
              <MenuItem
                data-testid="analytics-menu-item"
                icon={module?.AnalyticsIcon && <module.AnalyticsIcon />}
                open={open}
                sx={{ ...menuItemStyle }}
                text={t('track:page.analytics.title')}
              />
            </MenuLink>
          </IfFlag>
          <MenuLink data-testid="map-button" to={`/map/${things}`}>
            <MenuItem
              data-testid='thing-map-menu-item'
              icon={module?.MapIcon && <module.MapIcon />}
              open={open}
              sx={{ ...menuItemStyle }}
              text={t('track:page.thing-map.title')}
            />
          </MenuLink>
          <MenuLink data-testid="things-button" to={`/${things}`} className={setActive(`/${thing}/`)}>
            <MenuItem
              icon={module?.ThingIcon && <module.ThingIcon />}
              open={open}
              sx={{ ...menuItemStyle }}
              text={t('terms:thing', { count: T_MANY })}
            />
          </MenuLink>
          <MenuLink data-testid="persons-button" to={`/${people}`} className={setActive(`/${person}/`)}>
            <MenuItem
              data-testid='people-menu-item'
              icon={module?.PersonIcon && <module.PersonIcon />}
              open={open}
              sx={{ ...menuItemStyle }}
              text={t('terms:person', { count: T_MANY })}
            />
          </MenuLink>
          <MenuLink className={setActive(`/${alert}/`)} data-testid="alerts-button" to={`/${alerts}`} >
            <MenuItem
              data-testid='alert-menu-item'
              hasPermissions={hasAlertPermissions}
              icon={module?.AlertIcon && <module.AlertIcon />}
              open={open}
              sx={{ ...menuItemStyle }}
              text={t('common:terms.alert', { count: T_MANY })}
              handleClick={() => {
                if (location.pathname === `/${alerts}`) return;
                localStorage.removeItem('alert-list');
                localStorage.removeItem('alert-list-range');
              }}
            />
          </MenuLink>
        </>
      }
    </DrawerContent>
  );

  if (!moduleLoaded || isLoading) return <MiddleSpinner />;

  return (
    <App renderDrawerContent={renderDrawerContent} siteTitle={t('common:common.labels.manage')}>
      <PageContent routes={routes} className={showAppBanner ? 'showAppBanner' : undefined}>
        {showAppBanner
          && <AppBanner
            actionLabel={t('common:component.app-banner.action.end-session')}
            background={stc(account.display)}
            label={t('common:component.app-banner.labels.current-account', { account: account.display })}
            onAction={switchBackToOriginalAccount}
          />
        }
      </PageContent>
    </App>
  );
};

export default Body;
